import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";
import { graphql, useStaticQuery } from "gatsby";

import SEO from "../components/seo";
import Text from "../components/text";
import Title from "../components/title";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";
import Introduction from "../components/introduction";

const SAlive = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      sa1: file(name: { eq: "salive_01" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sa2: file(name: { eq: "salive_02" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zauberflasche: file(name: { eq: "zauberflasche_01" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Layout>
        <SEO title="sALIVE" />
        <Title text="sALIVE" />
        <Introduction>{intl.formatMessage({ id: "sa.intro_1" })}</Introduction>
        <Introduction>{intl.formatMessage({ id: "sa.intro_2" })}</Introduction>
        <Text>{intl.formatMessage({ id: "sa.text_1" })}</Text>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, 1fr);
            grid-gap: 20px;
            margin-top: 20px;
          `}
        >
          <Img
            fluid={data.sa1.childImageSharp.fluid}
            key={data.sa1.name}
            alt={data.sa1.name}
          />
          <Img
            fluid={data.sa2.childImageSharp.fluid}
            key={data.sa2.name}
            alt={data.sa2.name}
          />
          <Img
            fluid={data.zauberflasche.childImageSharp.fluid}
            key={data.zauberflasche.name}
            alt={data.zauberflasche.name}
          />
        </div>
        <Return />
      </Layout>
    </>
  );
};

export default SAlive;
